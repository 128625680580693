/*--------------------------------------------------------------------------*
* スムーズスクロール
*--------------------------------------------------------------------------*/
$(function(){
    $('a[href^="#"]').on('click',function(){
      let speed = 500;
      var header_height = $('header').height();
      let href= $(this).attr('href');
      let target = $(href == '#' || href == '' ? 'html' : href);
      let position = target.offset().top;
      $('html, body').animate({scrollTop:position}, speed, 'swing');
      return false;
    });
  });